import React from 'react'
import adp from '../../images/mask.png'
import './TeamCard.css'

export default function TeamCard() {
  return (
    <div className='team-mask'> 
        <img src={adp} alt="" />
    </div>
  )
}
